<template>
  <div class="print-gasto">
    <div class="pos-gasto text-center mt-2" v-if="gasto.gasto != undefined">
      <div class="header">
        <h2>
          {{ gasto.header.razon }}
        </h2>
        <p>
          Nit: {{ gasto.header.nit }}
          <br />
          Dirección: {{ gasto.header.direccion }}
          <br />
          Teléfono: {{ gasto.header.telefono }}
        </p>
      </div>
      <div class="body">
        <table>
          <tbody>
            <tr>
              <th>
                Fecha
              </th>
              <td>
                {{ moment(gasto.gasto.fecha).format("ll") }}
              </td>
            </tr>
            <tr>
              <th>
                Gasto
              </th>
              <td>
                {{ gasto.gasto.gasto }}
              </td>
            </tr>
            <tr>
              <th>
                Categoría
              </th>
              <td>
                {{ gasto.gasto.categoria }}
              </td>
            </tr>
            <tr>
              <th>
                Profesional
              </th>
              <td>
                <span v-if="gasto.gasto.profesional_id != null">
                  {{ gasto.gasto.profesional }}
                </span>
                <span v-else>
                  {{ gasto.gasto.profesional }}
                  <br />
                  <small>
                    Gasto de centro
                  </small>
                </span>
              </td>
            </tr>
            <tr>
              <th>
                Observación
              </th>
              <td>
                {{ gasto.gasto.observacion }}
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th></th>
              <th></th>
            </tr>
            <tr>
              <th>
                Subtotal
              </th>
              <th style="text-align:right !important">
                {{ gasto.gasto.valor | currency }}
              </th>
            </tr>
            <tr>
              <th>
                IVA
              </th>
              <th style="text-align:right !important">
                {{ gasto.gasto.iva | currency }}
              </th>
            </tr>
            <tr>
              <th>
                Total
              </th>
              <th style="text-align:right !important">
                {{ (gasto.gasto.valor + gasto.gasto.iva) | currency }}
              </th>
            </tr>
          </tfoot>
        </table>
        <table>
          <thead>
            <tr>
              <th>
                Efectivo
              </th>
              <th style="text-align:right !important">
                {{ gasto.gasto.efectivo | currency }}
              </th>
            </tr>
            <tr>
              <th>
                Transferencia
              </th>
              <th style="text-align:right !important">
                {{ gasto.gasto.transfer | currency }}
              </th>
            </tr>
            <tr>
              <th>
                Datafono
              </th>
              <th style="text-align:right !important">
                {{ gasto.gasto.datafono | currency }}
              </th>
            </tr>
          </thead>
        </table>
      </div>
    </div>
    <v-row>
      <v-col>
        <v-btn dark color="black" class="ml-2 d-print-none" @click="print()"
          >Imprimir</v-btn
        >
        <v-btn color="primary" class="ml-2 d-print-none" @click="close()"
          >Cerrar</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>
<script>
import * as moment from "moment";
moment.locale("es");
export default {
  name: "posGasto",
  data: () => ({
    gasto: {},
  }),
  methods: {
    loadDetalleGasto() {
      const body = {
        route: "/detalle_gasto",
        params: {
          id: this.$route.params.id,
        },
      };
      this.$store.dispatch("get_data", body).then((response) => {
        if (response.data.success) this.gasto = response.data.data;
      });
    },
    moment(data) {
      return moment(data);
    },
    close() {
      window.close();
    },
    print() {
      window.print();
    },
  },
  created() {
    this.loadDetalleGasto();
  },
  mounted() {
    const body = {
      permisos: JSON.stringify(["recepcion"]),
    };
    this.$store
      .dispatch("tokenValid", body)
      .then((response) => {
        if (!response.data.access_view) {
          this.$router.push("/home");
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status == 401) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("session_start");
          window.location.reload();
        }
      });
  },
};
</script>
<style scoped>
.pos-gasto {
  font-family: Arial;
  max-width: 80mm;
  margin: 0 auto;
  background: white;
  line-height: normal !important;
  color: black !important;
}

.header {
  font-weight: bold;
}

.header p {
  font-size: 14px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

table {
  width: 100%;
  padding: 8px;
}
table thead th,
tfoot th {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}
table td,
th {
  font-size: 14px !important;
}

table th {
  text-align: left !important;
}
table td {
  text-align: right !important;
}
</style>
